export const Tiles = () => {
  return (
    <div className="flex flex-col items-center mb-6 space-y-2">
      <div className="flex space-x-2">
        <div className="font-roboto-slab font-[300] text-lg bg-teal-300 w-fit px-3 rounded-xl">
          Java
        </div>
        <div className="font-roboto-slab font-[300] text-lg bg-teal-300 w-fit px-3 rounded-xl">
          JavaScript
        </div>
        <div className="font-roboto-slab font-[300] text-lg bg-teal-300 w-fit px-3 rounded-xl">
          MySQL
        </div>
      </div>
      <div className="flex flex-wrap gap-2 justify-center">
        <div className="font-roboto-slab font-[300] text-lg bg-yellow-200 w-fit px-3 rounded-xl">
          Spring Boot
        </div>
        <div className="font-roboto-slab font-[300] text-lg bg-yellow-200 w-fit px-3 rounded-xl">
          ReactJS
        </div>
        <div className="font-roboto-slab font-[300] text-lg bg-yellow-200 w-fit px-3 rounded-xl">
          ActiveMQ
        </div>
        <div className="font-roboto-slab font-[300] text-lg bg-yellow-200 w-fit px-3 rounded-xl">
          WebSockets
        </div>
        <div className="font-roboto-slab font-[300] text-lg bg-yellow-200 w-fit px-3 rounded-xl">
          Hibernate
        </div>
      </div>
    </div>
  );
};
