import React from "react";
import { Card } from "../journey/card";

export const Credentials = () => {
  return (
    <div className="flex flex-col z-auto items-center mx-12 mt-12 max-md:mx-6">
      <span className="w-8 h-8">👨‍🎓</span>
      <div className="flex flex-col w-full justify-start space-y-6 mt-6 border-2 border-dashed rounded-xl border-teal-400">
        <Card className="justify-start w-full pl-7">
          <ul className="list-dash marker:text-sky-600 marker:font-medium rounded-xl">
            <li>
              My capstone project was published at international conference{" "}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://ieeexplore.ieee.org/document/8940473"
              >
                IEEE
              </a>{" "}
              2019 New Delhi India
            </li>
            <li>
              Bachelor of Technology in Computer Science & Engineering with 8.24
              CGPA from VIT Chennai 2015-19.
            </li>
          </ul>
        </Card>
      </div>
    </div>
  );
};
