import { ReactNode } from "react";
import { cx } from "../../utils";

export const Card = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div className={cx("flex p-2 font-roboto-slab font-[300] ", className)}>
      {children}
    </div>
  );
};
