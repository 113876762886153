import React from "react";
import resumepdf from "../../resources/Kanishk-resume6-0.pdf";

export const Intro = (): JSX.Element => {
  return (
    <div className="flex flex-col space-y-3 mt-44 mb-[102px] justify-start md:ml-[18vw] max-md:ml-6 font-roboto-slab font-[200]">
      <div className="flex text-4xl">
        Hey, Kanishk here!{" "}
        <span className="flex flex-col justify-end pb-1 pl-1">
          <a href={resumepdf} download={"Kanishk's-Resume"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 48 48"
            >
              <g fill="#334155" className="hover:fill-sky-600">
                <path d="M24 37.1L13 24h22zM20 4h8v4h-8zm0 6h8v4h-8z" />
                <path d="M20 16h8v11h-8zM6 40h36v4H6z" />
              </g>
            </svg>
          </a>
        </span>
      </div>
      <div className="" id="content">
        <div className="text-3xl flex h-[36px] overflow-hidden" id="container">
          <span className="">I </span>
          <div className="pl-2">
            <ul className="animate-slide">
              <div className="flex space-x-2">
                <li className="italic h-[36px]">create</li>
                <span>WebApps</span>
              </div>
              <div className="flex space-x-2">
                <li className="italic h-[36px]">design</li>
                <span>Solutions</span>
              </div>
              <div className="flex space-x-2">
                <li className="italic h-[36px]">guide</li>
                <span>& Lead Team</span>
              </div>
              <div className="flex space-x-2">
                <li className="italic h-[36px]">develop</li>
                <span>Microservices</span>
              </div>
              <div className="flex space-x-2">
                <li className="italic h-[36px]">learn</li>
                <span className="animate-blink-caret border-r-[1px] border-solid"></span>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex space-x-1">
        <a
          href="https://www.linkedin.com/in/kanishk-sankpal-75944a136/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="20"
            height="20"
            viewBox="0 0 48 48"
          >
            <path
              fill="#0288D1"
              d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
            ></path>
            <path
              fill="#FFF"
              d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
            ></path>
          </svg>{" "}
        </a>
        <a
          href="https://github.com/ishqelliot"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="20"
            height="20"
            viewBox="0 0 48 48"
          >
            <path
              fill="#455A64"
              d="M40.3,15.7c0.6-1.7,1.2-5-0.4-8.7c-4.5,0-8.3,3.2-8.9,3.8c-2.2-0.5-4.6-0.7-7-0.7c-2.5,0-4.9,0.3-7.2,0.8C13.7,7.7,9.6,7,8,7c0,0-0.9,1.8-0.9,5c0,2,0.5,3.2,0.8,3.8C5.5,18.3,4,21.7,4,26.1c0,11.2,7.1,15,20,15s20-3.8,20-15C44,21.5,42.6,18.1,40.3,15.7z"
            ></path>
            <path
              fill="#FFCCBC"
              d="M24,39c-8.2,0-15-1.4-15-9c0-2.9,1.6-4.5,2.7-5.5c2.5-2.2,6.7-1.2,12.3-1.2c4.1,0,7.6-0.7,10.4,0.2c2.8,0.9,4.6,3.5,4.6,6.3C39,37.7,35,39,24,39z"
            ></path>
            <path
              fill="#D84315"
              d="M25,34c0,0.6-0.4,1-1,1s-1-0.4-1-1s0.4-1,1-1S25,33.4,25,34z M26.5,36.5c0.2-0.2,0.2-0.5,0-0.7s-0.5-0.2-0.7,0c-0.9,0.9-2.6,0.9-3.5,0c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7c0.7,0.7,1.5,1,2.5,1S25.8,37.1,26.5,36.5z"
            ></path>
            <path
              fill="#FFF"
              d="M19,29.5c0,2.5-1.3,4.5-3,4.5s-3-2-3-4.5s1.3-4.5,3-4.5S19,27,19,29.5z M32,25c-1.7,0-3,2-3,4.5s1.3,4.5,3,4.5c1.7,0,3-2,3-4.5S33.7,25,32,25z"
            ></path>
            <path
              fill="#6D4C41"
              d="M34,30c0,1.7-0.9,3-2,3s-2-1.3-2-3c0-0.2,0-0.5,0.1-0.7c0.1,0.4,0.5,0.7,0.9,0.7c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1c-0.2,0-0.4,0.1-0.6,0.2c0.4-0.7,0.9-1.2,1.6-1.2C33.1,27,34,28.3,34,30z M16,27c-0.7,0-1.2,0.5-1.6,1.2c0.2-0.1,0.4-0.2,0.6-0.2c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1c-0.4,0-0.8-0.3-0.9-0.7c0,0.2-0.1,0.5-0.1,0.7c0,1.7,0.9,3,2,3s2-1.3,2-3S17.1,27,16,27z"
            ></path>
          </svg>
        </a>
      </div>
    </div>
  );
};
