import React, { BaseSyntheticEvent, useState } from "react";
import { Intro, Journey, Credentials, Tiles } from "./components";

const App = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState(["active", "", ""]);

  const onClick = (e: BaseSyntheticEvent) => {
    switch (e.target.id) {
      case "tab-1": {
        setActiveTab(["active", "", ""]);
        const element = document.getElementById("journey");
        const headerOffset = 80;
        const elementPosition = element?.getBoundingClientRect().top ?? 0;
        const offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;
        // window?.focus();
        window?.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
        console.log(offsetPosition, window.pageYOffset, elementPosition);
        break;
      }
      case "tab-2": {
        setActiveTab(["", "active", ""]);
        const element = document.getElementById("credentails");
        const headerOffset = 80;
        const elementPosition = element?.getBoundingClientRect().top ?? 0;
        const offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;
        // window?.focus();
        window?.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
        break;
      }
      case "tab-3": {
        setActiveTab(["", "", "active"]);
        break;
      }
      default:
        break;
    }
  };
  return (
    <div className="flex flex-col justify-center mb-8">
      <div
        className="fixed overflow-hidden top-0 z-10 bg-white flex justify-center space-x-4 p-4 w-full font-[300]"
        role="tablist"
        aria-orientation="horizontal"
        onClick={onClick}
      >
        <button
          className="py-2.5 text-m leading-5 font-roboto-slab font-[300] w-60 focus:outline-none data-[selected=active]:border-b-2 data-[selected=active]:border-b-teal-300 hover:bg-teal-200/10"
          id="tab-1"
          role="tab"
          type="button"
          tabIndex={0}
          data-selected={activeTab[0]}
          aria-controls="headlessui-tabs-panel-4"
        >
          Journey
        </button>
        <button
          className="py-2.5 text-m leading-5 font-roboto-slab w-60 focus:outline-none data-[selected=active]:border-b-2 data-[selected=active]:border-b-teal-300 hover:bg-teal-200/10"
          id="tab-2"
          role="tab"
          type="button"
          tabIndex={-1}
          data-selected={activeTab[1]}
          aria-controls="headlessui-tabs-panel-5"
        >
          Credentials
        </button>
        <button
          className="py-2.5 text-m leading-5 font-roboto-slab w-60 focus:outline-none data-[selected=active]:border-b-2 data-[selected=active]:border-b-teal-300 hover:bg-teal-200/10"
          id="tab-3"
          role="tab"
          type="button"
          tabIndex={-1}
          data-selected={activeTab[2]}
          aria-controls="headlessui-tabs-panel-6"
        >
          Playground
        </button>
      </div>
      <Intro />
      <div>
        <Tiles />
      </div>
      <div id="journey">
        <Journey />
      </div>
      <div id="credentails">
        <Credentials />
      </div>
    </div>
  );
};

export default App;
