import { Card } from "./card";

export const Journey = () => {
  return (
    <div className="flex flex-col z-auto items-center mx-12 max-md:mx-6">
      <span className="w-8 h-8">🚀</span>
      <div className="flex flex-col w-full space-y-6 mt-6">
        <div className="flex">
          <span className="w-0 h-0 absolute border-[15px] ml-[-15px] rounded border-teal-200 border-l-transparent border-r-transparent border-b-transparent"></span>
          <div className="flex justify-start max-md:mr-10 mr-20 border-2 rounded-xl border-teal-200 bg-teal-200">
            <Card>
              Started professional experience with summer internship at SRI
              Labs, Mysuru in 2018. Because of my interest and college projects
              in CBIR, i got role of Computer Vision developer, where i worked
              on prototype to develop assitive technology for visually impaired
            </Card>
          </div>
        </div>
        <div className="flex justify-end relative">
          <div className="flex justify-end max-md:ml-10 ml-20 border-2 rounded-xl border-teal-200 bg-teal-200">
            <Card className="justify-start w-full">
              <p>
                Got placed in Deutsche Bank in 2019, was assigned role of UI
                Engineer under Asset Management body. Worked on regulatory
                project <i>Cost-Calc</i>, where got chance to develop efficient{" "}
                <i>search</i> and <i>filters</i>
              </p>
            </Card>
          </div>
          <span className="w-0 h-0 absolute border-[15px] right-0 mr-[-15px] rounded border-teal-200 border-l-transparent border-r-transparent border-b-transparent"></span>
        </div>
        <div className="flex">
          <span className="w-0 h-0 absolute border-[15px] ml-[-15px] rounded border-teal-200 border-l-transparent border-r-transparent border-b-transparent"></span>
          <div className="flex justify-start max-md:mr-10 mr-20 border-2 rounded-xl border-teal-200 bg-teal-200">
            <Card className="justify-start w-full">
              <p>
                In 2020, I got assigned to a more critical project Transaction
                Reporting Dashboard which supports 3 regulatory teams of EU
                MIFID, EMIR, and SFTR. I worked as Full-Stack Engineer under TR
                from 2020 to 2022. TR became a major cost-saving project and
                helped mitigate huge regulatory fines by supporting audit
                timelines and regulatory checks.
                <span className="text-sm bg-teal-100 p-2 mx-2 mt-2 rounded-xl block">
                  <q className="text-justify inline-block">
                    The advanced features of the SFTR Dashboard do indeed make
                    life easier for RAR and I'd encourage all RAR team members
                    to use the SFTR dashboard whenever research on specific
                    SLEBs is required. Quick. Simple, Reliable!
                  </q>
                  <span className="flex justify-end">
                    - Senior Operations Specialist Global Reporting
                  </span>
                </span>
              </p>
            </Card>
          </div>
        </div>
        <div className="flex justify-end relative">
          <div className="flex justify-end max-md:ml-10 ml-20 border-2 rounded-xl border-teal-200 bg-teal-200">
            <Card className="justify-start w-full">
              <p>
                For UI I used <i>ReactJS</i> and <i>Spring Boot</i> for apis. TR
                had numerous notable features like long-running query which
                processed ~50M records, data monitoring, analytics and had
                implemented cache using <i>hazelcast.</i>
                <span className="text-sm bg-teal-100 p-2 mx-2 mt-2 rounded-xl block">
                  <q className="text-justify inline-block">
                    Thank you for creating such a useful and effective tool
                    helping us to cope with the daily Ops Reg Reporting
                    processes and making indeed our lives much easier.
                  </q>
                  <span className="flex justify-end">
                    - Operations Specialist Global Reporting (RAR Team)
                  </span>
                </span>
              </p>
            </Card>
          </div>
          <span className="w-0 h-0 absolute border-[15px] right-0 mr-[-15px] rounded border-teal-200 border-l-transparent border-r-transparent border-b-transparent"></span>
        </div>
        <div className="flex">
          <span className="w-0 h-0 absolute border-[15px] ml-[-15px] rounded border-teal-200 border-l-transparent border-r-transparent border-b-transparent"></span>
          <div className="flex justify-start max-md:mr-10 mr-20 border-2 rounded-xl border-teal-200 bg-teal-200">
            <Card className="justify-start w-full">
              <p>
                In project TR, I handled complete delivery and project ownership
                from the first development step till the prod release. I passed{" "}
                <i>Change Initiator's</i> exam and was authorized and
                responsible for application release.
              </p>
            </Card>
          </div>
        </div>
        <div className="flex relative justify-end">
          <div className="flex justify-end max-md:ml-10 ml-20 border-2 rounded-xl border-teal-200 bg-teal-200">
            <Card className="justify-start w-full">
              <p>
                Along with active application development, contributed to
                platform space and got involved in the introduction of new
                essential designs in the platform. Took ownership of loads of
                tech stack migration like core framework pipeline migration
                which involved migration from TC to Jenkins, major spring
                upgradation, and gradle templates, UIX migration which revolved
                around moving to CRA, moving out of deprecated in-house
                open-source state-management library muster and JS to TS.
              </p>
            </Card>
          </div>
          <span className="w-0 h-0 absolute border-[15px] right-0 mr-[-15px] rounded border-teal-200 border-l-transparent border-r-transparent border-b-transparent"></span>
        </div>
        <div className="flex">
          <span className="w-0 h-0 absolute border-[15px] ml-[-15px] rounded border-teal-200 border-l-transparent border-r-transparent border-b-transparent"></span>
          <div className="flex justify-start max-md:mr-10 mr-20 border-2 rounded-xl border-teal-200 bg-teal-200">
            <Card className="justify-start w-full">
              <p>
                Was awarded <i>CEO Recognition award</i> in 2021 for
                contributing beyond expectations and proactively taking
                ownership of multiple projects across different technologies
                resulting in remarkable cross-functional collaboration.
              </p>
            </Card>
          </div>
        </div>
        <div className="flex justify-end relative">
          <div className="flex justify-end max-md:ml-10 ml-20 border-2 rounded-xl border-teal-200 bg-teal-200">
            <Card className="justify-start w-full">
              <p>
                Got promoted to <i>Associate Engineer</i> in 2022
              </p>
            </Card>
          </div>
          <span className="w-0 h-0 absolute border-[15px] right-0 mr-[-15px] rounded border-teal-200 border-l-transparent border-r-transparent border-b-transparent"></span>
        </div>
        <div className="flex justify-start">
          <span className="w-0 h-0 absolute border-[15px] ml-[-15px] rounded border-teal-200 border-l-transparent border-r-transparent border-b-transparent"></span>
          <div className="flex justify-start max-md:mr-10 mr-20 border-2 rounded-xl border-teal-200 bg-teal-200">
            <Card className="justify-start w-full">
              <p>
                Took up the role of UI lead at the start of 2022 at a crucial
                time. Led team of 9 engineers working across 3 locations and 2
                time zones.Organized regular technology discussion platform for
                the collective growth of the team along with regular
                one-on-ones. Mentored and guided team with design principles and
                code standards.
              </p>
            </Card>
          </div>
        </div>
        <div className="flex relative justify-end">
          <div className="flex justify-end max-md:ml-10 ml-20 border-2 rounded-xl border-teal-200 bg-teal-200">
            <Card className="justify-start w-full">
              <p>
                Project NAVA - Traders Compliance tool, worked as Full Stack
                Engineer, developed generic control-flow-service using
                spring-boot that caters needs to multiple trading controls, its
                generic design helped in improving the pace of completion and
                gave more time to focus on other crucial tasks. Control-flow
                service helps in passing the record within the team more
                conveniently while improving the clarity of collaboration at the
                same time.
              </p>
            </Card>
          </div>
          <span className="w-0 h-0 absolute border-[15px] right-0 mr-[-15px] rounded border-teal-200 border-l-transparent border-r-transparent border-b-transparent"></span>
        </div>
        <div className="flex relative justify-start">
          <span className="w-0 h-0 absolute border-[15px] ml-[-15px] rounded border-teal-200 border-l-transparent border-r-transparent border-b-transparent"></span>
          <div className="flex justify-start max-md:mr-10 mr-20 border-2 rounded-xl border-teal-200 bg-teal-200">
            <Card className="justify-start w-full">
              <p>
                In NAVA, I used concepts of WebSockets to implement a feature of
                live updates, from the backend I used <i>ActiveMQ</i> to publish
                events and used <i>STOMP protocol</i> at UI to listen to
                published messages.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
